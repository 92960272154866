<template>
  <v-app id="mammut-os">
    <v-app-bar
      app
      flat
      short
      dark
      color="primary"
      clipped-right
    >
      <template v-if="organization">
        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click="drawer = !drawer"
        />
        <v-menu max-height="300">
          <template #activator="{ on }">
            <v-btn
              v-if="organizations.length > 1"
              text
              rounded
              :ripple="false"
              v-on="on"
            >
              {{ organization.name }}
              <v-icon small>
                mdi-chevron-down
              </v-icon>
            </v-btn>
            <v-toolbar-title v-else>
              {{ organization.name }}
            </v-toolbar-title>
          </template>
          <v-card outlined width="300">
            <v-list-item exact :to="{ name: 'organizations' }">
              See all organizations
            </v-list-item>
            <v-list-item :to="{ name: 'organizations-new' }">
              Add an organization
            </v-list-item>
            <v-divider />
            <v-subheader>
              My Organizations
            </v-subheader>
            <template v-for="item in organizations">
              <v-list-item
                v-if="item.id !== organization.id"
                :key="item.id"
                active-class="primary--text"
                @click="changeOrganization(item.id)"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-card>
        </v-menu>
      </template>
      <v-img
        v-else
        class="flex-grow-0"
        src="/logo_white.svg"
        max-height="80%"
        contain
        position="left"
      />
      <v-spacer />
      <v-menu
        close-on-click
        close-on-content-click
        offset-y
        min-width="150"
      >
        <template #activator="{ on }">
          <v-btn
            icon
            text
            dark
            v-on="on"
          >
            <v-avatar
              v-if="user.photoURL"
            >
              <v-img :src="user.photoURL" />
            </v-avatar>
            <v-icon v-else>
              mdi-account
            </v-icon>
          </v-btn>
        </template>
        <v-list class="text-right">
          <v-subheader>
            {{ user.displayName || user.email }}
          </v-subheader>
          <v-divider />
          <v-list-item :to="{ name: 'account' }">
            <v-list-item-title>My Account</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-if="organization && $route.name !== 'get-started'"
      v-model="drawer"
      app
      dark
      color="primary darken-3"
      :mini-variant="$vuetify.breakpoint.mdAndUp && mini"
      mobile-breakpoint="960"
    >
      <v-list nav>
        <v-img
          src="/logo_white.svg"
          width="200"
          :min-height="!mini ? '70' : null"
          contain
          class="mx-auto mt-3"
          :class="{ 'mb-3': mini }"
          position="top"
        >
          <v-container v-if="!mini" class="fill-height d-flex justify-end align-end">
            <span class="font-weight-light caption mb-n3 mr-n3">
              Beta v{{ require('@/../package.json').version }}
            </span>
          </v-container>
        </v-img>
        <v-divider v-if="!mini" class="mb-2" />
        <v-list-item
          v-for="(item, i) in filteredItems"
          :key="i"
          :to="item.to"
          exact
          active-class="primary"
          @click="navClick(item.click)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="v-navigation-drawer__footer">
        <v-divider light />
        <!-- Mini -->
        <v-list-item
          three-line
          class="align-center px-0"
        >
          <v-row no-gutters :justify="mini ? 'center' : 'end'">
            <v-btn
              icon
              class="hidden-sm-and-down mx-2"
              @click="mini = !mini"
            >
              <v-icon>
                mdi-chevron-{{ mini ? 'right' : 'left' }}
              </v-icon>
            </v-btn>
          </v-row>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view
          :key="(organization || {}).id"
          @screenshot="$emit('screenshot', $event)"
          @broadcast="$emit('broadcast', $event)"
          @stop-broadcast="$emit('stop-broadcast', $event)"
          @reload="$emit('reload', $event)"
          @reload-frame="$emit('reload-frame', $event)"
          @reload-query="$emit('reload-query', $event)"
          @restart="$emit('restart', $event)"
          @shutdown="$emit('shutdown', $event)"
        />
      </v-fade-transition>
    </v-main>
    <v-navigation-drawer
      v-if="layout"
      v-model="$store.state.previewDrawer"
      app
      right
      :stateless="$vuetify.breakpoint.smAndUp"
      clipped
      color="primary"
      :width="!$vuetify.breakpoint.xsOnly ? '30vw' : null"
    >
      <template v-if="layout">
        <v-subheader dark>
          Layout Preview
        </v-subheader>
        <m-previewer
          :resolution="layout.resolution"
          max-height="80vh"
        >
          <iframe :src="layoutPreviewUrl" />
        </m-previewer>
        <v-card-actions class="justify-center">
          <v-btn
            dark
            small
            outlined
            @click="openPreviewWindow"
          >
            <v-icon left>
              mdi-open-in-new
            </v-icon>
            Open In New Window
          </v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
    <snackbars />
    <asset-uploader />
  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MPreviewer from '@backend/components/MPreviewer'
import Snackbars from '@backend/components/Snackbars'
import { auth } from '@/plugins/firebase'
export default {
  name: 'BaseLayout',
  components: {
    MPreviewer,
    Snackbars
  },
  data: () => ({
    drawer: null,
    preview: false,
    mini: true,
    appName: 'Mammut [OS]',
    loading: false,
    help: false,
    items: [
      // {
      //   icon: 'mdi-home',
      //   title: 'Overview',
      //   to: { name: 'index' }
      // },
      {
        icon: 'mdi-view-carousel',
        title: 'Displays',
        to: { name: 'displays' }
      },
      {
        icon: 'mdi-collage',
        title: 'Layouts',
        to: { name: 'layouts' }
      },
      {
        icon: 'mdi-folder-multiple-image',
        title: 'Media Library',
        to: { name: 'media' }
      },
      {
        icon: 'mdi-cog',
        title: 'Organization Settings',
        to: { name: 'settings' }
      },
      {
        icon: 'mdi-download',
        title: 'Downloads',
        to: { name: 'downloads' }
      },
      {
        icon: 'mdi-help-circle-outline',
        title: 'Help',
        click: 'toggleHelp'
      }
    ],
    baseUrl: process.env.NODE_ENV === 'development'
      ? 'http://frame.mammutos.local:8080'
      : 'https://frame.mammutos.com',
    layoutPreviewUrl: undefined
  }),
  computed: {
    ...mapState(['user', 'organizations']),
    ...mapGetters(['layout', 'organization']),
    filteredItems() {
      return (this.user && this.user.email === 'denizar@gmail.com') ? this.items : this.items.filter(x => x.icon !== 'mdi-settings')
    }
  },
  watch: {
    'layout.id': {
      handler(id, oldId) {
        if (oldId && !id) {
          this.$store.state.previewDrawer = false
        }
        if (!oldId && id) {
          if (this.$vuetify.breakpoint.smAndUp) {
            this.$store.state.previewDrawer = true
          }
          this.layoutPreviewUrl = `${this.baseUrl}/layout/${id}?preview=true&t=${Date.now()}`
        }
      },
      immediate: true
    },
    'organization.id': {
      handler(organizationId) {
        this.$store.dispatch('setOrganizationId', organizationId)
      },
      immediate: true
    },
    help: {
      handler(help) {
        const html = document.getElementsByTagName('html')[0]
        if (help) {
          html.classList.add('help')
          window.tidioChatApi.open()
        } else {
          html.classList.remove('help')
          window.tidioChatApi.close()
        }
      },
      immediate: true
    }
  },
  async beforeMount() {
    await this.$store.dispatch('bindMComponents')
  },
  mounted() {
    window.tidioChatApi.on('close', this.onTidioChatClose)
  },
  methods: {
    async logout() {
      await auth.signOut()
      window.location.reload()
    },
    changeOrganization(organizationId) {
      this.$store.dispatch('setOrganizationId', organizationId)
      this.$router.push({ name: 'index' })
    },
    navClick(method) {
      if (method === 'toggleHelp') {
        this.help = !this.help
      }
    },
    onTidioChatClose() {
      this.help = false
    },
    openPreviewWindow() {
      if (!this.layout || !this.layout.resolution) {
        return
      }
      const popupWidth = this.$vuetify.breakpoint.xsOnly ? window.innerWidth : parseInt(window.innerWidth * 0.8)
      const { width, height } = this.layout.resolution
      const aspectRatio = width && height ? width / height : null
      if (!aspectRatio) {
        return
      }
      const popupHeight = parseInt(popupWidth / aspectRatio)
      const iframe = `<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0} iframe {border: 0}</style></head><body><iframe src="${this.layoutPreviewUrl}" style="height:100%;width:100%"></iframe></html></body>`
      const win = window.open(
        '',
        // `/preview/layout/${this.layout.id}`,
        'preview',
        `
          scrollbars=no,
          resizable=yes,
          status=no,
          location=no,
          toolbar=no,
          menubar=no,
          titlebar=no,
          width=${popupWidth},
          height=${popupHeight},
          left=-1000,
          top=-1000
        `
      )
      win.document.write(iframe)
    }
  }
}
</script>

<style lang="sass">
  .container.page
    max-width: 960px
    margin-left: initial
    padding: 1em
  nav.v-navigation-drawer
    > .v-navigation-drawer__image
      opacity: 0.3
    .v-list-item
      justify-content: flex-start !important
    &.v-navigation-drawer--mini-variant
      .v-list-item__content
        justify-content: flex-start !important
      .only-mini
        visibility: hidden
    .v-navigation-drawer__footer
      position: absolute
      bottom: 0
      width: 100%
</style>
