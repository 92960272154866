<template>
  <div>
    <v-snackbar
      v-for="(snackbar, i) in snackbars"
      :key="snackbar.id"
      bottom
      :timeout="snackbar.timeout === undefined ? 3000 : snackbar.timeout"
      :value="true"
      :color="snackbar.type || 'primary'"
      :style="{ 'margin-bottom': `${(snackbars.length - (i + 1)) * 60}px` }"
      @input="!$event && dismissSnackbar(snackbar.id)"
    >
      {{ snackbar.message }}
      <v-btn
        dark
        icon
        @click="dismissSnackbar(snackbar.id)"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Snackbars',
  computed: {
    ...mapState(['snackbars'])
  },
  methods: {
    ...mapActions(['dismissSnackbar'])
  }
}
</script>
